import React from 'react';
import styled from 'styled-components';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Link } from 'src/app/link';
import { FluidImg } from 'src/embeds/image/image-dato';
import * as css from './drawer-featured-cta.css';
import defaultUrl from './drawer-ctas.pattern.svg';
import MDXComponents from 'src/components/mdx-components';
import { SectionHeader, Section } from 'src/components/molecules/Section';

const Content = styled.div`
  background-color: #5a17ee;
  display: flex;
  background-image: none;
  border-radius: 0.95rem;
  background-size: cover;
  padding: 3rem 1rem;
  @media (min-width: ${({ theme }) => theme.smallBreakpoint}) {
    padding: 2.5rem 3rem;
  }

  @media (min-width: ${({ theme }) => theme.mediumBreakpoint}) {
    padding: 2rem 6rem;
    background-image: ${({patternUrl}) => {
      return `url(${patternUrl ? patternUrl : defaultUrl})`
    }};
  }

  @media (min-width: ${({ theme }) => theme.largeBreakpoint}) {
    padding: 3rem 7rem;
  }
`;

export default function Drawer({
  heading,
  content,
  contentNode,
  backgroundImage,
  customData,
  callouts
}) {
  callouts = callouts.filter((callout) => callout.__typename == 'DatoCmsCallout');
  const drawerCustomData = JSON.parse(customData);

  return (
    <MDXProvider components={MDXComponents}>
      <Section maxWidth="xlarge">
        <SectionHeader {...(drawerCustomData?.flags?.sectionHeader || {})}>
          {!!heading && <h2>{heading}</h2>}
          {content.length > 0 && <MDXRenderer>{contentNode.childMdx.body}</MDXRenderer>}
        </SectionHeader>
        <Content patternUrl={backgroundImage?.url}>
          {callouts.map(callout => {{
            const {
              preHeading,
              heading,
              content,
              contentNode,
              ctas,
              media
            } = callout;
            return (
              <>
              <div css={[css.contentSubContainer]}>
                {preHeading && (
                  <p className="f-mono" css={[css.preHeading]}>
                    {preHeading}
                  </p>
                )}
                <h2 css={css.heading}>{heading}</h2>
                {content.length > 0
                  && <MDXRenderer>
                      {contentNode.childMdx.body}
                    </MDXRenderer>}
                {ctas.map(cta => {
                  return (
                    <div>
                      <Link
                        href={cta.url}
                        css={[css.button, css.primaryButton]}
                        aria-label={cta.a11yText}
                      >
                        {cta.text}
                      </Link>
                    </div>
                  )
                })}
              </div>
              {media && (
                <FluidImg
                  data={media}
                  width={media.width}
                  role="presentation"
                  maxWidth="100%"
                  constrain
                />
              )}
              </>
            )
          }})}

        </Content>
      </Section>
    </MDXProvider>
  );
}
