import { css } from 'styled-components';

export * from './common.css';
import { media } from 'src/system2';

export const contentSubContainer = css`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  color: #fff;
`;

export const section = css`
  margin: 2rem auto;
  max-width: var(--outer-width-xl,100%);

  @media (min-width: ${({ theme }) => theme.mediumBreakpoint}) {
    padding-right: var(--outer-x);
    padding-left: var(--outer-x);
  }
`;

export const preHeading = css`

  margin-bottom: 0.7rem;
  color: #fff;
`

export const heading = css`
  color: white;
  max-width: 20em;
`;

export const button = css`
  color: white;
  font-weight: 500;
  display: inline-flex;
  background: white;
  border: 2px solid white;
  border-radius: 0.75em;
  font-size: 14px;
  padding: 0.6em 1.6em;
  &:hover,
  &:active {
    text-decoration: none;
  }
`;

export const primaryButton = css`
  color: #5a17ee;
  &:hover,
  &:active {
    text-decoration: none;
    color: #340f87;
  }
`;

export const secondaryButton = css`
  color: white;
  background: none;
`;

export const buttonList = css`
  display: flex;
  justify-content: center;
  > * + * {
    margin-left: 20px;
  }
`;

export const buttonListItem = css`
  display: flex;
`;
